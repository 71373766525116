<template>
  <nav class="listing-group-bottom-right">
    <section style="padding: 16px; background-color: rgba(255,255,255,0.75); border-radius: 8px;">
      <b-field>
        <b-radio v-model="mapStyle"
                 @input="styleChanged"
                 size="is-small"
                 native-value="mapbox://styles/mapbox/streets-v11?optimize=true">
          Streets
        </b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="mapStyle"
                 @input="styleChanged"
                 size="is-small"
                 native-value="mapbox://styles/mapbox/satellite-streets-v11?optimize=true">
          Satellite
        </b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="mapStyle"
                 @input="styleChanged"
                 size="is-small"
                 native-value="mapbox://styles/mapbox/light-v11?optimize=true">
          Light
        </b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="mapStyle"
                 @input="styleChanged"
                 size="is-small"
                 native-value="mapbox://styles/mapbox/dark-v11?optimize=true">
          Dark
        </b-radio>
      </b-field>
      <b-field>
        <b-radio v-model="mapStyle"
                 @input="styleChanged"
                 size="is-small"
                 :native-value="highResolutionStyle">
          High resolution
        </b-radio>
      </b-field>
    </section>
  </nav>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "map-style-menu",
  data(){
    return{
      mapStyle: '',
      highResolutionStyle: null
    }
  },
  mounted() {
    this.mapStyle = this.$store.getters.getMapStyle
    const rasterTilesSource = {
      type: 'raster',
      tiles: [
        'https://map.webtunel.com/download?z={z}&y={y}&x={x}'
      ],
      tileSize: 256,
      attribution: 'Map tiles by RTT'
    };
    const rasterTilesLayer = {
      id: 'simple-tiles',
      type: 'raster',
      source: 'raster-tiles',
      minzoom: 5,
      maxzoom: 22
    };
    this.highResolutionStyle = {
      version: 8,
      sources: {
        'raster-tiles': rasterTilesSource
      },
      layers: [rasterTilesLayer]
    }
  },
  methods:{
    ...mapActions({
      setMapStyle: 'setMapStyle'
    }),
    styleChanged(style){
      this.setMapStyle(style)
      this.$emit('onStyleChanged', style)
    }
  }
}
</script>

<style scoped>

</style>